.coverage {
  .summary-label {
    width: 150px;
    display: inline-block;
    font-weight: 600;
  }
  .value {
    display: inline-block;
    vertical-align: top;
  }
  .coverage-benefits {
    .row {
      margin: 1em 0em;
    }
  }
  .carrier {
    margin-bottom: 4em;
  }

  .see-other {
    display: inline-block;
    vertical-align: middle;
  }
  .card-info {
    .thumbnail {
      margin-bottom: 0px;
    }
  }
}
