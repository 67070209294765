footer.footer {
  padding-top: 11px;
  padding-bottom: 11px;
  color: #fff;
  background-color: #000;
  .container {
  }
  .cvt-logo {
    width: 333px;
  }
}
