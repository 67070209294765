.services {
  display: table;
  height: 100%;
  width: 100%;

  .container-big {
    padding: 1em;
    text-align: center;
    vertical-align: middle;
  }

  .big-logo {
    width: 100px;
    margin-bottom: 15px;
  }

  @media(min-width: 450px) {
    h1.heading {
      font-size: 3.55em;
    }
  }

  @media(min-width: 760px) {
    h1.heading {
      font-size: 3.05em;
    }
  }

  @media(min-width: 900px) {
    h1.heading {
      font-size: 3.25em;
      margin: 0 0 0.3em;
    }
  }

  .carbox {
    display: block;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: box-shadow .25s;
    &:hover {
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    }
  }

  .img-carbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    padding: 15px;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 2px;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 2px;
    overflow: hidden;

    img {
      //object-fit: cover;
      width: 100%;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all .25s ease;
    }
  }

  .carbox-content {
    padding: 15px;
    text-align: left;
  }

  .carbox-title {
    margin-top: 0;
    font-weight: 700;
    font-size: 1.65em;
    a {
      color: #000;
      text-decoration: none !important;
    }
  }

  .carbox-read-more {
    border-top: 1px solid #d4d4d4;
    a {
      text-decoration: none !important;
      padding: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  h1.heading {
    font-size: 1.15em;
    font-weight: 900;
    margin: 0 0 0.5em;
    color: #505050;
  }
}
