.navbar-default {

}
.navbar.navbar-upper {
  min-height: 0px;
  .navbar-nav > li > a {
    padding: 10px 10px;
  }
}

.navbar.navbar-lower {
  border: none;
  background-color: #fff;
  .container {
    .ns-main-menu-wrapper {
      display: flex;
      background-color: white;
      min-height: 80px;
      border: none;
      margin-bottom: 0px;
      .navbar-nav {
        font-size: 17px;
        li > a {
          line-height: 33px;
        }
      }
    }
  }
}

.divide-nav {
  font-size: 10px;
}
