@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "navbar";
@import "overview";
@import "coverage";
@import "maximize-benefits";
@import "footer";

body {
  -webkit-font-smoothing: antialiased;
  font-family: Arial, sans-serif;
}

.section-title {
  border-bottom: 2px solid #777;
  margin-right: 10%;
  padding: .2em;
  display: inline-block;
}

h1 {
  margin-bottom: 1em;
}

section {
  margin: 1em 0;
}
