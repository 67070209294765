.overview-cta {
  color: $gray-lighter;
  background: #b20837;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #b20837, #b31217);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #b20837, #b31217); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  img {
  }
  h1 {
    border: none;
    margin-bottom: none;
  }
}

.tab-pane {
  display: none;
}

.plans-sidebar-wrapper {
  -webkit-box-shadow: 7px 0 5px -3px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    7px 0 5px -3px rgba(50, 50, 50, 0.2);
  box-shadow:         7px 0 5px -3px rgba(50, 50, 50, 0.2);
  padding: 3em 0em;
  margin: 0em 0em 3em 0em;
}

.plans-sidebar {
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border: none;
  }
  vertical-align: middle;
  border: none;
  .plan {
    border: none;

    .plan-type-image {
      height: 3em;
      display: inline-block;
    }

    .panel-title {
      display: inline-block;
      vertical-align: middle;
    }

    .panel-body {
      border: none;
    }

    .plan-name {
      @extend h4;
      margin-bottom: 0px;
    }

    .district {
      font-size: .9em;
      font-style: italic;
    }

    .plan-details {
      line-height: 1.5em;
      margin-top: .5em;
      margin-bottom: .5em;
    }

    .print-card {
      font-size: .9em;
      font-style: italic;
    }
  }

  .btn-primary {
    margin: 1em;
  }
}



//@extend-elements
//original selectors
//.timeline .line::before, .timeline .line::after
%extend_1 {
  content: '';
  position: absolute;
  left: -4px;
  width: 12px;
  height: 12px;
  display: block;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  background: currentColor;
}


.timeline {
  position: relative;
  padding: 21px 0 10px;
  margin-top: 4px;
  margin-bottom: 30px;

  .line {
    position: absolute;
    width: 4px;
    display: block;
    background: currentColor;
    top: 0;
    bottom: 0;
    margin-left: 16.6666666667%;
  }

  .separator {
    border-top: 1px solid currentColor;
  }

  .text-muted {
    font-style: italic;
    font-size: .9em;
    padding: .2em;
    float: right;
  }

  .line::before {
    top: -4px;
    @extend %extend_1;
  }

  .line::after {
    bottom: -4px;
    @extend %extend_1;
  }

  .panel {
    position: relative;
    margin: 10px 0 21px 70px;
    clear: both;
    .panel-heading.icon {
      position: absolute;
      left: -94px;
      display: block;
      width: 40px;
      height: 40px;
      padding: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 50%;
      text-align: center;
      float: left;
      * {
        font-size: 20px;
        vertical-align: middle;
        line-height: 40px;
      }
    }
  }

  .panel::before {
    position: absolute;
    display: block;
    top: 8px;
    left: -24px;
    content: '';
    width: 0;
    height: 0;
    border: inherit;
    border-width: 12px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .panel-outline {
    border-color: transparent;
    background: transparent;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;

    .panel-body {
      padding: 10px 0;
    }

    .panel-footer {
      display: none;
    }

    .panel-heading:not(.icon) {
      display: none;
    }
  }
}

.subscriber-info {
  .gavatar{
    color: rgb(255, 255, 255);
    background-color: #919296;
    display: inline-block;
    font-size: 36px;
    border-radius: 80px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: auto;
    display: block;
    text-align: center;
  }

  .gavatar-small {
    @extend .gavatar;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
}
